a {
	color: rgba(0, 0, 0, 0.5);
}

a:hover {
	color: rgba(0, 0, 0, 0.7);
}

ul {
	list-style-type: circle;
	padding-inline-start: 0rem;
}
